/* Modal overlay */
.liv-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal container */
.liv-modal-content {
    background: white;
    width: 400px;
    height: 600px;
    max-width: 90%;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: liv-fadeIn 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

/* Close button */
.liv-close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #555;
}

/* User Table */
.liv-user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    border-radius: 15%;
}

.liv-user-table th,
.liv-user-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.liv-user-table th {
    background-color: #f2f2f2;
}

/* Online and Offline row colors */
.liv-user-table tr.liv-online {
    background-color: rgba(0, 255, 0, 0.1);
}

.liv-user-table tr.liv-offline {
    background-color: rgba(255, 0, 0, 0.1);
}

/* User avatar */
.liv-user-avatar {
    width: 30px;
    border-radius: 15%;
    object-fit: cover;
}

/* Headings */
.liv-heading {
    margin-top: 15px;
    font-size: 18px;
    color: #333;
}

.red-dot {
    width: 12px;
    color: red;
}

.green-dot {
    width: 12px;
    color: #30fc03;
}

/* Fade-in animation */
@keyframes liv-fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}